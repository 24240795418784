import { CanActivateFn } from '@angular/router';
import { injectEnvironment } from '../../../../../core/src';

export const legacyURLredirectionGuard: CanActivateFn = (route, state) => {
  // Inject environment once and directly construct the URL
  const url =
    injectEnvironment().bookingUrl + state.url.replace('/booking/', '');

  if (url.includes('reschedule') && !!route.queryParams['rescheduleEvent']) {
    return true;
  }

  // Redirect to the constructed URL
  window.open(url, '_self');

  // Always return false to prevent route activation after redirection
  return false;
};
