<cf-toast-container></cf-toast-container>
<cf-pull-to-refresh></cf-pull-to-refresh>
<cf-header
  *ngIf="!hideHeader"
  (toggleSidenav)="showSidenav = !showSidenav"
></cf-header>

<div class="wrapper">
  <cf-sidenav
    [isAuthorized]="authService.isAuthorized$ | async"
    (toggleSidenav)="showSidenav = !showSidenav"
    class="d-none d-md-block {{ showSidenav ? 'pinned' : '' }}"
    *ngIf="!hideHeader"
  ></cf-sidenav>

  <main [class.cf-root-main-container]="!hideHeader">
    <div [class.container]="!hideHeader">
      <header
        [class.d-none]="hideHeader"
        cf-header-user-flow
        (login)="login()"
        (logout)="logout()"
        (register)="register()"
        [isAuthorized]="authService.isAuthorized$ | async"
      ></header>

      <router-outlet></router-outlet>

      <cf-footer *ngIf="isAuthorized && !hideHeader"></cf-footer>
    </div>
  </main>
</div>
